import type { Fields } from '@wilm/shared-types/validation-rules/types';

export const validate = (field: Fields[keyof Fields], fields: Fields) => {
    if (field.validation.required && !field.value?.toString()) {
        return { showError: true, message: field.validation.errorMessages?.validation };
    } else if (field.validation.requiredPredicate?.(fields) && !field.value) {
        return { showError: true, message: field.validation.errorMessages?.validation };
    } else if (
        typeof field.value === 'string' &&
        field.value.length > 0 &&
        field.validation.minLength &&
        field.value.length < field.validation.minLength
    ) {
        return { showError: true, message: field.validation.errorMessages?.range };
    } else if (typeof field.value === 'string' && field.validation.maxLength && field.value.length > field.validation.maxLength) {
        return { showError: true, message: field.validation.errorMessages?.range };
    } else if (
        typeof field.value === 'string' &&
        field.value.length &&
        field.validation.regex &&
        !field.validation.regex.test(field.value)
    ) {
        return { showError: true, message: field.validation.errorMessages?.regex };
    } else if (field.validation.sameAsPredicate && !field.validation.sameAsPredicate?.(fields)) {
        return { showError: true, message: field.validation.errorMessages?.validation };
    } else if (field.validation.validate) {
        return field.validation.validate(fields);
    }

    return {};
};
